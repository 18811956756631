import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import { Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import groupAllocationClients from './helpers/groupAllocationClients';
import { extractErrorMessage } from '~/utils/error/error';
import { mergeAllocationByUserId } from '~/utils/clientAllocation';
import extractDateFromString from '~/utils/date/extractDateFromString';
import getIsGlobalUser from '~/utils/roles/getIsGlobalUser';

import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Loader from '~/ui/components/common/Loader';
import {
  ACT_TEAM_MANAGEMENT,
  DUPLICATE_CLIENT_ALLOCATION,
  EDIT_CLIENT_ALLOCATION,
  MY_ACT_TEAM,
  MY_CLIENT_ALLOCATIONS,
  VIEW_ACT_TEAM,
} from '~/ui/constants/paths';

import EditIcon from '~/ui/assets/images/editWhiteSecond.svg';
import { ReactComponent as GlobeIcon } from '~/ui/assets/images/globe.svg';
import styles from './ViewClientAllocation.module.scss';

type IParams = {
  actTeamId: string;
  clientAllocationDate: string;
};

const ViewClientAllocation = (): ReactElement => {
  const { actTeamId, clientAllocationDate } = useParams<IParams>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const { clinic: userClinic } = useStoreState(state => state.user.current);
  const clientAllocations = useStoreState(state => state.clientAllocation.current);
  const locationGroups = useStoreState(state => state.clientAllocation.locationGroups);
  const actTeam = useStoreState(state => state.actTeam.current);
  const { isArchived } = actTeam || {};

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { onGetClinicActTeam, onGetActTeamsAvailable } = useStoreActions(
    actions => actions.actTeam,
  );
  const { onGetClientAllocation } = useStoreActions(actions => actions.clientAllocation);
  const { showError } = useStoreActions(actions => actions.snackbar);
  const {
    onGetAllocationClientsByGroup,
    onGetAllocationClientsByGroupGU,
    onGetClientAllocationGU,
  } = useStoreActions(actions => actions.clientAllocation);

  const { isActTeam, isActTeamMember, isGlobalUser } = useRole();

  const hasOwnAllocation = clientAllocations?.some(item => item.isOwn && !item.isArchived);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const payload = { clinicId: String(userClinic.id), actTeamId };
      const allocationDate = `${clientAllocationDate}T00:00:00Z`;

      await onGetMyClinic();

      if (isGlobalUser) {
        await onGetActTeamsAvailable({ clinicId: String(userClinic.id) });
      } else {
        await onGetClinicActTeam(payload);
      }

      if (isGlobalUser) {
        await onGetClientAllocationGU({
          clinicId: String(userClinic.id),
          clientAllocationDate: allocationDate,
        });
        await onGetAllocationClientsByGroupGU({ clinicId: String(userClinic?.id) });
      } else {
        await onGetClientAllocation({
          clinicId: String(userClinic.id),
          teamId: actTeamId,
          clientAllocationDate: allocationDate,
        });
        await onGetAllocationClientsByGroup({
          clinicId: String(userClinic?.id),
          teamId: actTeamId,
        });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    actTeamId,
    clientAllocationDate,
    isGlobalUser,
    onGetActTeamsAvailable,
    onGetAllocationClientsByGroup,
    onGetAllocationClientsByGroupGU,
    onGetClientAllocation,
    onGetClinicActTeam,
    onGetMyClinic,
    showError,
    onGetClientAllocationGU,
    userClinic.id,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const filteredAllocations = useMemo(
    () => (clientAllocations || []).filter(a => !a.isArchived),
    [clientAllocations],
  );

  if (loading || (!actTeam && !isGlobalUser) || !clientAllocations) return <Loader />;

  const breadcrumbItems = [
    ...(isGlobalUser ? [{ to: MY_CLIENT_ALLOCATIONS, title: `${userClinic.name} Teams` }] : []),
    ...(isActTeam
      ? [
          {
            title: actTeam.name,
            to: {
              pathname: MY_ACT_TEAM,
              state: { selectedTab: 0 },
            },
          },
        ]
      : []),
    ...(!isActTeam && !isGlobalUser
      ? [
          { to: ACT_TEAM_MANAGEMENT, title: `${userClinic.name} Teams` },
          {
            to: {
              pathname: VIEW_ACT_TEAM.replace(':actTeamId', String(actTeam.id)),
              state: { selectedTab: 2 },
            },
            title: actTeam.name,
          },
        ]
      : []),
    { to: pathname, title: 'Client Allocation' },
  ];

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2>{extractDateFromString(clientAllocations[0].date)}</h2>
          <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
        </div>
        {!isArchived && (
          <>
            <div>
              <Button
                color="primary"
                variant="outlined"
                className={styles.buttonWrapper}
                disabled={isActTeamMember && !hasOwnAllocation}
                onClick={() =>
                  navigate(
                    DUPLICATE_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                      ':clientAllocationDate',
                      String(clientAllocations[0].date.split('T')[0]),
                    ),
                  )
                }
              >
                Duplicate
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<img src={EditIcon} alt="edit" />}
                disabled={isActTeamMember && !hasOwnAllocation}
                onClick={() =>
                  navigate(
                    EDIT_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                      ':clientAllocationDate',
                      String(clientAllocations[0].date.split('T')[0]),
                    ),
                  )
                }
              >
                Edit Client Allocation
              </Button>
            </div>
          </>
        )}
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Team Members</TableCell>
              <TableCell>Sub-Role</TableCell>
              <TableCell>Clients</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mergeAllocationByUserId(filteredAllocations).map(allocation => {
              if (allocation.isArchived) {
                return null;
              }

              const allocationClients = groupAllocationClients(
                locationGroups,
                allocation.user.clients,
              );

              const hasGlobalUserRole = getIsGlobalUser(allocation.user.roles, userClinic?.id);

              return (
                <TableRow key={allocation.id}>
                  <TableCell>
                    <Box className={styles.nameCell}>
                      {hasGlobalUserRole && <GlobeIcon width={20} height={20} />}
                      {allocation.user.name}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {allocation.user.subRoles.map(subRole => subRole.name).join(', ')}
                  </TableCell>
                  <TableCell className={styles.locationGroups}>
                    {allocation.locationGroups?.length
                      ? allocationClients.map(group => {
                          if (!group.clients?.length) {
                            return null;
                          }
                          return (
                            <Fragment
                              key={`location-group-${allocation.id}-${group.locationGroup.id}`}
                            >
                              <strong>{`${group.locationGroup.name}:`}</strong>
                              {`${group.clients.map(client => client.name).join(', ')}`}
                            </Fragment>
                          );
                        })
                      : allocation.user.clients
                          .map(client => `${client.firstName} ${client.lastName}`)
                          .join(', ')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ViewClientAllocation;
