import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IAiActions, IThunkGetAiSystemSettings } from '../types';

const onGetAiSystemSettings: IThunkGetAiSystemSettings = thunk(
  async ({ setAiSystemSettings, resetAiSystemSettings }: Actions<IAiActions>) => {
    resetAiSystemSettings();
    const { data } = await api.ai.getAiSettings();

    setAiSystemSettings(data.config);
  },
);

export default onGetAiSystemSettings;
